import React, { useState, useEffect } from "react";
import style from "./search-bar.module.scss";

type Props = {
  defaultValue?: string;
  placeholder?: string;
  onSearch: (searchInput: string) => void;
}

const SearchBar: React.FC<Props> = ({ defaultValue, placeholder, onSearch }) => {
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setSearchInput(defaultValue || '');
  }, [defaultValue]);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const handleInputChange = () => {
    onSearch(searchInput);
  };

  return (
    <div className={style['search-bar']}>
      <input type="text" placeholder={placeholder} value={searchInput} onChange={handleSearchInputChange} />
      <button onClick={handleInputChange}>Search</button>
    </div>
  )
}

export default SearchBar;

import React, { useState, useEffect } from 'react';
import style from './category-template-list.module.scss';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
  SortableContainerProps,
  SortableElementProps,
} from 'react-sortable-hoc';
import {
  updateTemplateSequence as updateTemplateSequenceApi
} from '../../api/category';

type Template = {
  id: number;
  name: string;
}
interface CategoryTemplateList {
  categoryId: number;
  list: Template[];
}

const DragHandle = SortableHandle(() => <div className={style['drag-handler']} />);

const SortableItem: React.ComponentClass<
  SortableElementProps & { value: Template },
  any
> = SortableElement(({ value }: { value: Template }) => (
  <div className={style['template']}>
    <DragHandle />
    {value.name}
  </div>
  ));

  const SortableList: React.ComponentClass<
    SortableContainerProps & { items: Template[] },
    any
  > = SortableContainer(({ items }: { items: Template[] }) => {
    return (
      <div className={style['list']}>
        {items.map((value: Template, index: number) => (
          <SortableItem key={`item-${index}`} index={index} value={value} />
        ))}
      </div>
    );
  });

const CategoryTemplateList: React.FC<CategoryTemplateList> = ({
  list,
  categoryId,
}) => {
  const [templateList, setTemplateList] = useState(list);
  const [submitting, setSubmitting] = useState(false);

  const updateTemplatesSequence = async () => {
    const templateIds = templateList.map(({ id }) => {
      return id;
    });

    await setSubmitting((prev) => !prev);
    await updateTemplateSequenceApi(categoryId, templateIds);
    await setSubmitting((prev) => !prev);
  }

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex === newIndex) {
      return;
    }

    console.log(`oldIndex=${oldIndex}, newIndex=${newIndex}`);
    setTemplateList((prevList) => {
      return arrayMove(prevList, oldIndex, newIndex);
    });
  };

  return (
    <>
      <SortableList
        items={templateList}
        onSortEnd={onSortEnd}
        lockAxis="y"
        useDragHandle
      />
      {submitting ? (
        'submitting'
      ) : (
        <button onClick={updateTemplatesSequence}>Update Sequence</button>
      )}
    </>
  );
};

export default CategoryTemplateList;

import React from 'react';
import style from './loading-screen.module.scss';

type Props = {
  text?: string;
}

const LoadingScreen: React.FC<Props> = ({ text }) => {
  return (
    <div className={style['loading']}>
      {text || 'Loading...'}
    </div>
  );
};

export default LoadingScreen;

import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';

const CSRF_COOKIE_NAME = 'csrf_token';
const EXPIRE_DAYS = 30;

export const setCookie = (
  name: string,
  value: string,
  expires: number = EXPIRE_DAYS,
  path = '/',
) => {
  Cookies.set(name, value, { path, expires });
};

export const getCookie = (name: string) => {
  return Cookies.get(name) || false;
};

export const deleteCookie = (
  name: string,
  path = '/',
) => {
  return Cookies.remove(name, { path });
};

export const setCsrfCookie = () => {
  Cookies.set(CSRF_COOKIE_NAME, uuid(), { expires: EXPIRE_DAYS });
};

export const getCsrfCookie = () => {
  return Cookies.get(CSRF_COOKIE_NAME);
};

export const isCsrfCookieExisted = () => {
  return Boolean(Cookies.get(CSRF_COOKIE_NAME));
};

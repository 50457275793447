import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import style from './multipleCheckboxes.module.scss';

type FormValues = {
  categories: string[];
};

interface MultipleCheckboxes {
  list: { [key: string]: string[] };
  checkedList: string[];
  updateHandler: (categories: string[]) => void;
};

const MultipleCheckboxes: React.FC<MultipleCheckboxes> = ({
  list,
  checkedList,
  updateHandler
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();
  const onSubmit = async (data: { categories: string | string[] }) => {
    const payload =
      typeof data.categories == 'string' ? [data.categories] : data.categories;
    await updateHandler(payload);
  }
  
  const renderCategoryCheckboxes = () => {
    return Object.keys(list).map((key) => {
      return (
        <div className={style['category']} key={key}>
          <h3>{key}</h3>
          {renderCheckbox(list[key])}
        </div>
      )
    });
  };

  const renderCheckbox = (list: string[]) => {
    return list.map((item) => {
      return (
        <label key={item}>
          <input
            type="checkbox"
            value={item}
            defaultChecked={checkedList.includes(item)}
            {...register('categories')}
          />{' '}
          {item}
        </label>
      )
    })
  };

  return (
    <>
      { isSubmitting && 'submitting' }
      <form id="hook-form" className={style['form']} onSubmit={handleSubmit(onSubmit)}>
        {list && renderCategoryCheckboxes()}
      </form>
      {isSubmitting ? 'submitting' : <button type="submit" form="hook-form">submit</button>}
    </>
  );
};

export default MultipleCheckboxes;

import { postData, ResponseType } from '../utils/fetch';
import { User } from '../components/FraudUsers/types';

export const goToFraudUsers = (searchUserIds: string) => {
  window.location.href = `/administration/fraud_users?search_user_ids=${searchUserIds}`;
};

export const updateFraudUsers = (userIds: number[]): Promise<ResponseType<Omit<User,'checked'>[]>> => {
  return postData(`/administration/users/block_users`, { user_ids: userIds });
}

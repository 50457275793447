import { setCsrfCookie, getCsrfCookie, isCsrfCookieExisted } from '../utils/cookie';
type RequestMethod = 'GET' | 'DELETE' | 'POST' | 'PUT' | 'PATCH';

/* eslint-disable */
export type ResponseType<T = void> = {
  data: T;
  status: number;
  statusText: string;
  ok: boolean;
};

const callFetch = async<R, P = void>(
  url: string,
  method: RequestMethod,
  body: P,
  options: any = {},
): Promise<ResponseType<R>> => {
  if (!isCsrfCookieExisted()) {
    setCsrfCookie();
  }
  const { headers = {} } = options;
  const response = await fetch(url, {
    ...options,
    headers: {
      'content-type': 'application/json',
      'Csrf-Token': getCsrfCookie(),
      ...headers,
    },
    credentials: 'include',
    method,
    body: JSON.stringify(body),
  });

  const { status, statusText, ok } = response;
  let data;
 
  if (response.status === 204) {
    data = await response.text();
  } else {
    const isPdfContentType = response.headers.get('content-type') === 'application/pdf';
    if (isPdfContentType) {
      data = await response.blob();
    } else {
      const res = await response.json();
      data = res
    }
  }

  if (status >= 400) {
    // eslint-disable-next-line no-throw-literal
    throw {
      code: status,
      message: data.error,
    };
  }

  return { data, status, statusText, ok };
};

export const getData = <R>(url: string, options?: any) => callFetch<R>(url, 'GET', undefined, options);
export const deleteData = <R>(url: string, options?: any) => callFetch<R>(url, 'DELETE', undefined, options);
export const postData = <R, P>(url: string, data: P, options?: any) => callFetch<R, P>(url, 'POST', data, options);
export const putData = <R, P>(url: string, data: P, options?: any) => callFetch<R, P>(url, 'PUT', data, options);
export const patchData = <R, P>(url: string, data: P, options?: any) => callFetch<R, P>(url, 'PATCH', data, options);

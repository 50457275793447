import React from "react";
import style from './HelloWorld.module.scss';

type HelloWorld = {
  name: string;
}

const HelloWorld: React.FC<HelloWorld> = ({ name }) => {
  return(
    <div className={style['red']}>Hello World from {name}!</div>
  );
};

export default HelloWorld;

import React from 'react';
import style from './category-group-list.module.scss'

type Category = {
  id: number;
  name: string;
}

interface CategoryGroupList {
  list: { [key: string]: Category[] }
}

const CategoryGroupList: React.FC<CategoryGroupList> = ({ list }) => {
  console.log('list', list);

  const renderRootCategories = () => {
    return Object.keys(list).map((key) => {
      return (
        <div className={style['root_category']} key={key}>
          <h3>{key}</h3>
          {renderCategoryChildren(list[key])}
        </div>
      )
    })
  }

  const renderCategoryChildren = (list: Category[]) => {
    return list.map((item) => {
      return (
        <div className={style['category']} key={item.name}>
          <a href={`/administration/categories/${item.id}`}>{item.name}</a>
        </div>
      );
    })
  };

  return (
    <div className={style['root']}>
      {renderRootCategories()}
    </div>
  )
}
export default CategoryGroupList;

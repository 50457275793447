import React from 'react';
import { updateTemplateCategories } from '../../api/template';
import MultipleCheckboxes from '../MultipleCheckboxes';

type CategorySelector = {
  templateId: number;
  categoryGroup: { [key: string]: string[] };
  selectedCategories: string[];
}

const CategorySelector: React.FC<CategorySelector> = ({
  templateId,
  categoryGroup,
  selectedCategories,
}) => {
  const updateTemplateCategoriesHandler = async (categories: string[]) => {
    await updateTemplateCategories(templateId, categories)
  }

  return categoryGroup ? (
    <MultipleCheckboxes
      list={categoryGroup}
      checkedList={selectedCategories}
      updateHandler={updateTemplateCategoriesHandler}
    />
  ) : (
    <></>
  );

}

export default CategorySelector;

import React, { useState, useEffect } from 'react';
import style from './fraud-users.module.scss';
import { goToFraudUsers, updateFraudUsers } from '../../api/fraudUsers'
import { User } from './types'

import LoadingScreen from '../LoadingScreen';
import SearchBar from '../SearchBar';

type Props = {
  usersData: Omit<User,'checked'>[];
}

const FraudUser: React.FC<Props> = ({ usersData = [] }) => {
  const [loading, setLoading] = useState(false);
  const [enableBlockButton, setEnableBlockButton] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    console.log(usersData)
    setUsers(usersData.map((item) => ({...item, checked: false})));
  }, [usersData]);

  useEffect(() => {
    const isBlockButtonEnabled = users.filter((user) => user.checked).length > 0 ;
    setEnableBlockButton(isBlockButtonEnabled);
  }, [users]);

  const searchHandler = (userIds: string) => {
    // Because this is GET method,
    // Nginx server has a limit on the query string, So we need to limit too.
    const idsLengthLimit = 1024;

    const isValid = /^\d+(,\d+)*$/.test(userIds);
    if (!isValid) {
      alert('The format of the search text is incorrect. Please re-enter.');
      return;
    }

    if (userIds.length >= idsLengthLimit) {
      const sliceUsersSize = userIds.slice(0, idsLengthLimit).split(',').length;

      alert(`You cannot search for more than ${sliceUsersSize} users.`);
      return;
    }

    goToFraudUsers(userIds);
  };

  const checkboxChangeHandler = (id: number) => {
    const updatedUsers = users.map((user) =>
      user.id === id ? { ...user, checked: !user.checked } : user
    );
    setUsers(updatedUsers);
  };

  const isShowCheckbox = (user: User) => {
    return !user.isFraud
  }

  const checkAllChangeHandler = () => {
    const updatedUsers = users.map((user) => ({
      ...user,
      checked: isShowCheckbox(user) && !checkAll
    }));
    setUsers(updatedUsers);
    setCheckAll(!checkAll);
  };

  const updateFraudUsersHandler = async () => {
    setLoading(true);

    const checkedIds = users
      .filter((user) => user.checked)
      .map((user) => user.id);

    try {
      const { data } = await updateFraudUsers(checkedIds);

      const updateUsers = data.map((item) => ({...item, checked: false}))

      setUsers((prevState) => mergeAndUpdateUsers(prevState, updateUsers) );
      alert('Block Success!!');
    } catch (error) {
      console.log(error);
      alert('Block Fail! Please contact our developer');
    }

    setLoading(false);
  };

  const mergeAndUpdateUsers = (prevState: User[], updateUsers: User[]) => {
    return prevState.map((user) => {
      const updateUser = updateUsers.find(({id}) => (
        id === user.id
      ));

      return updateUser || user
    })
  };

  const renderStatusTag = (status: boolean) => {
    const statusText = status ? 'yes': 'no'

    return (
      <span className={`status_tag ${statusText}`}>{statusText}</span>
    );
  };

  return (
    <div>
      {loading && <LoadingScreen text='In Processing...'/>}
      <SearchBar
        defaultValue={users.map((user) => user.id).join(',')}
        placeholder='User ids e.g. 101,102,103'
        onSearch={searchHandler}
      />
      <div className={style['table-container']}>
        <table className='index_table'>
          <thead>
            <tr>
              <th className={style['col-check']}>
                <label>
                  <input
                    type="checkbox"
                    checked={checkAll}
                    onChange={checkAllChangeHandler}
                  />
                </label>
                &nbsp;Check
              </th>
              <th>User ID</th>
              <th>User Email</th>
              <th>Block & Flag as fraud</th>
              <th>Cancel Subscription</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>
                {isShowCheckbox(user) && (
                  <input
                    type="checkbox"
                    checked={user.checked}
                    onChange={() => checkboxChangeHandler(user.id)}
                  />
                )}
                </td>
                <td>{user.id}</td>
                <td>{user.email}</td>
                <td>{user.isFraud? renderStatusTag(true) : renderStatusTag(false)}</td>
                <td>
                  {
                    user.hasSubscription ? (
                      renderStatusTag(user.isCancelledSubscription)
                    ) : (
                      ''
                    )
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <button disabled={!enableBlockButton} onClick={updateFraudUsersHandler}>Block Users</button>
      </div>
    </div>
  );
}

export default FraudUser;
